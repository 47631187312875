import React, { useState, useEffect } from 'react';
import { Route } from "react-router-dom";
import ErrorPage from '../pages/ErrorPage';
import { Helmet } from 'react-helmet-async';

const PublicRouter = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setLoaded(true), 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <Route {...rest} render={props => (
            <>
                <Helmet>
                    <title>
                        {rest.title ? `${rest.title} - Connect.ID Intelligence` : "Connect.ID Intelligence"}
                    </title>
                </Helmet>
                <ErrorPage><Component {...props} /></ErrorPage>
            </>
        )} />
    );
};

export default PublicRouter;
