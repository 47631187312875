import React from 'react';
import './body.css';
import { ApolloProvider } from '@apollo/react-hooks';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import client from './graphql/client';
import Routes from './routes/Routes';
import AppContextComponent from "./components/AppState";
import { useTranslation } from 'react-i18next';
// import '@ant-design/compatible';
// import 'antd/dist/antd.css';

import "react-datetime/css/react-datetime.css";
// core styles
import "./scss/volt.scss";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-datetime/css/react-datetime.css";
import { HelmetProvider } from 'react-helmet-async';

const cubejsApi = cubejs(
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJteWFwcGlkIjozLCJpYXQiOjE2MTgyMjkzNDIsInVzZXJfaWQiOjM4MDgxLCJwbGF0Zm9ybV9pZCI6MSwic2VydmljZV9pZCI6OCwiY29tcGFueV9pZCI6OTM0NX0.lYSmPrF55TH3y8HQk96khmXn08XBt5nkUh7vzxfyak4',
  { apiUrl: `/cubejs-api/v1` }
);

const App = ({ children }) => {
  const { t, i18n } = useTranslation();

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <ApolloProvider client={client}>
        <AppContextComponent>
          <HelmetProvider>
            <Routes />
          </HelmetProvider>
        </AppContextComponent>
      </ApolloProvider>
    </CubeProvider>
  );
};

export default App;
