import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Route, Redirect } from "react-router-dom";
import { isEmpty } from "../AppConfig";
import { withRouter } from 'react-router-dom';
import AppContext from '../components/AppContext';
import ErrorPage from '../pages/ErrorPage';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';

const PrivateRoute = ({ location, component: Component, ...rest }) => {
    const localStorageIsContracted = () => {
        return localStorage.getItem('sidebarContracted') === 'false' ? false : true
    }

    const [contracted, setContracted] = useState(localStorageIsContracted());
    const [contractSidebar, setContractSidebar] = useState(localStorageIsContracted());
    const [valid, setValid] = useState(false);

    const tokenFromLocalStorage = localStorage.getItem("token");
    const contextData = useContext(AppContext);

    useEffect(() => {
        window.localStorage.setItem("path", location.pathname);
        if (!contextData.cryptoKey) {
            axios.get(
                `${process.env.REACT_APP_API_KEY}/CommonV2/GetCryptoKey`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                }
            }
            )
                .then(response => {
                    if (response.data) {
                        contextData.changeCryptoKey(response.data)
                    }
                })
                .catch(error => {
                    console.log("login error from sso", error);
                });
        }
    }, []);

    const resize = () => {
        let resize = setInterval(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10);
        setTimeout(function () {
            clearInterval(resize);
        }, 301);
    }

    const toggleContracted = () => {
        setContracted(!contracted);
        setContractSidebar(!contracted);
        localStorage.setItem('sidebarContracted', !contracted);
        resize();
    };

    const toggleMouseOver = () => {
        if (contracted) {
            setContractSidebar(!contractSidebar);
        }
        resize();
    };

    return (
        contextData.cryptoKey && <Route {...rest} render={props => (
            <>
                {!isEmpty(tokenFromLocalStorage) ?
                    <>
                        <Header setValid={setValid} toggleContracted={toggleContracted} />
                        <main className="content" id="maindiv">
                            <Sidebar
                                contracted={contractSidebar}
                                onMouseEnter={toggleMouseOver}
                                onMouseLeave={toggleMouseOver}
                            />
                            <Helmet>
                                <title>
                                    {rest.title ? `${rest.title} - Connect.ID Intelligence` : "Connect.ID Intelligence"}
                                </title>
                            </Helmet>
                            <ErrorPage><Component {...props} /></ErrorPage>
                        </main>
                    </>
                    :
                    <Redirect to='/login' />
                }
            </>
        )
        }
        />
    );

};

export default withRouter(PrivateRoute);