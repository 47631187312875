import axios from "axios";
import React, { useState } from "react";
import { baseURL } from "../apis/utilities/constant";
import { decryptData } from "../utils/crypto";


const Feedback = ({ onClose, setMessage, cryptoKey }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [file, setFile] = useState(null);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const handleFileChange = (e) => {
        const uploadedFile = e.target.files[0];
        setFile(uploadedFile);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        // Append form fields to FormData
        formData.append('title', title);
        formData.append('description', description);
        if (file) {
            formData.append('file', file);  // Append the file as well
        }
        try {
            const response = await axios.post(baseURL + '/CommonV2/SendFeedback', formData, {
                headers: {
                    Authorization: `Bearer ${decryptData(localStorage.getItem("token"), cryptoKey)}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data) {
                if (response.data.status === "Success") {
                    setMessage({status: "Success", message: response.data.message})
                    onClose();
                } else {
                    setMessage({status: "Failed", message: response.data.message})
                }
            }
        } catch (error) {
            setMessage({status: "Failed", message: "Something went wrong!"})
        }
    };

    return (
        <>
            <div className="modal-overlay feedback-popupbox">
                <div className="modal-content-feedback">
                    <div className="modal-header">
                        <h2 class="h5 modal-title h4">Feedback Form</h2>
                        <button aria-label="Close" type="button" class="btn btn-close" onClick={onClose}>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} className="feedback-form">
                        <div className="form-group">
                            <label className="form-label">Title</label>
                            <input
                                type="text "
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                placeholder="Enter title"
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Description</label>
                            <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Enter description"
                                rows="4"
                                required
                                className="form-control"
                            />
                        </div>

                        <div className="form-group">
                            <label className="form-label">Upload File</label>
                            <input type="file" onChange={handleFileChange} />
                        </div>

                        <button type="submit" className="mb-2 btn btn-primary btn-md mt-1">Submit</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Feedback;
